// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();

require("@rails/activestorage").start();
require("../javascript/channels");
require("@popperjs/core");

import "@hotwired/turbo-rails";




window.Cookies = require("js-cookie");

import "../javascript/packs/analytics";
import initVue from "./init_vue";

import chart from "./line_chart";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener("turbo:load", () => {
  setTimeout(function () {
    Array.from(document.getElementsByClassName(".flash-notices")).forEach(
      (element) => {
        element.slideUp(1000);
      }
    );
  }, 5000);
});

initVue();
document.addEventListener("turbo:load", () => {
  // console.log("calling chart")
  chart();
});

document.addEventListener("turbo:load", () => {
  addMetaTag("csrf-param", "authenticity_token");

  var token = Cookies.get("csrftoken");

  addMetaTag("csrf-token", token);
});

function addMetaTag(name, content) {
  let meta = document.createElement("meta");
  meta.name = name;

  meta.setAttribute("content", content);

  document.getElementsByTagName("head")[0].appendChild(meta);
  // console.log("Adding a meta tag", meta);
}

document.addEventListener("turbo:load", function () {
  // console.log("turbo:load");
});

// document.addEventListener('turbo:before-fetch-request', (event) => {
//   // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
//   event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
//   var metaTag = document.querySelector("meta[name='csp-nonce']");
//   if (!metaTag) return console.error("Could not find meta tag with name 'csp-nonce'");
//   event.detail.fetchOptions.headers['X-Turbo-Nonce'] = metaTag.getAttribute('content');
// })

// // Put this somewhere in /app/javascript
// document.addEventListener("turbo:before-cache", function() {
//   let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
//   for (var element of scriptTagsToAddNonces) {
//     element.setAttribute('nonce', element.nonce);
//   }
// });
