import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import NavBar from "./components/NavBar";
import PricingPage from "./components/PricingPage";
import ProjectDashboard from "./components/ProjectDashboard";
import LogViewer from "./components/LogViewer";
import Cookies from "js-cookie";
export default () => {
  document.addEventListener("turbo:load", () => {
    let element = document.getElementById("vue-nav");

    if (element) {
      const props = JSON.parse(element.getAttribute("data"));
      //console.log("props is " + JSON.stringify(props));

      const app = createApp(NavBar, props);
      // let token = document
      //   .getElementsByName("csrf-token")[0]
      //   .getAttribute("content");

      let token = Cookies.get("csrftoken");

      axios.defaults.headers.common["X-CSRF-Token"] = token;
      axios.defaults.headers.common["Accept"] = "application/json";
      app.use(VueAxios, axios);

      app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
      app.mount("#" + element.id);
      // console.log("mounted vue nav bar");
    } else {
      // console.log("No element found");
    }
  });

  document.addEventListener("turbo:load", () => {
    let element = document.getElementById("vue-pricing-page");

    if (element) {
      const props = JSON.parse(element.getAttribute("data"));
      //console.log("props is " + JSON.stringify(props));

      const app = createApp(PricingPage, props);
      // let token = document
      //   .getElementsByName("csrf-token")[0]
      //   .getAttribute("content");

      let token = Cookies.get("csrftoken");

      axios.defaults.headers.common["X-CSRF-Token"] = token;
      axios.defaults.headers.common["Accept"] = "application/json";
      app.use(VueAxios, axios);

      app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
      app.mount("#" + element.id);
      // console.log("mounted vue nav bar");
    } else {
      // console.log("No element found");
    }
  });

  // this is only good for one per page
  document.addEventListener("turbo:load", () => {
    let element = document.getElementById("vue-log-viewer");
    if(element){
      const props = JSON.parse(element.getAttribute("data"));
      //console.log("props is " + JSON.stringify(props));
      const app = createApp(LogViewer, props);
      app.mount("#" + element.id);
    }
  });


  document.addEventListener("turbo:load", () => {
    let element = document.getElementById("vue-project-dashboard");

    if (element) {
      const props = JSON.parse(element.getAttribute("data"));
      //console.log("props is " + JSON.stringify(props));

      const app = createApp(ProjectDashboard, props);
      // let token = document
      //   .getElementsByName("csrf-token")[0]
      //   .getAttribute("content");

      let token = Cookies.get("csrftoken");

      axios.defaults.headers.common["X-CSRF-Token"] = token;
      axios.defaults.headers.common["Accept"] = "application/json";
      app.use(VueAxios, axios);

      app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
      app.mount("#" + element.id);
      // console.log("mounted vue nav bar");
    } else {
      // console.log("No element found");
    }
  });
};
