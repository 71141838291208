window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

gtag("js", new Date());

const trackGoogleAnalytics = (event) => {
  gtag("config", "G-W6WSL4N3JG", {
    cookie_flags: "max-age=7200;secure;samesite=none",
  });
};

document.addEventListener("turbo:load", trackGoogleAnalytics);
// console.log("Analytics loaded");
