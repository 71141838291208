<template>
    <div class="min-h-full">
        <span class="text-2xl font-bold ml-8 mt-4">
        Log Output
        </span>
        <template v-if="loading">

            <div class="flex items-center ">
                <FolderIcon class="block h-6 w-6 text-indigo-500 animate-spin" />
            </div>

        </template>
        <template v-else>
            <div  class="ml-8 mr-8 mt-2 lg:w-[64rem] md:w-[48rem] overflow-y-auto overflow-scroll bg-zinc-900 text-zinc-100 rounded-md" >
            <div :class="'p-4 rounded-md text-sm ' + themeName"  v-html="logData">


            </div>
        </div>
        </template>
    </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ref, defineProps } from 'vue'
import { FolderIcon } from '@heroicons/vue/24/outline'

import {
        default as AnsiUp
    } from 'ansi_up';
    const ansi_up = new AnsiUp({ use_classes: true});
    ansi_up.use_classes = true

const navigation = [
    { name: 'Dashboard', href: '#', current: true },
    { name: 'Team', href: '#', current: false },
    { name: 'Projects', href: '#', current: false },
    { name: 'Calendar', href: '#', current: false },
]
const userNavigation = [
     { name: 'Your Profile', href: '/profile/edit' },
     {name: 'Account Settings', href: '/account'},
    { name: 'Your Projects', href: '/dashboard' },
    { name: 'Your Orgs', href: '/orgs' },
    { name: 'Sign out', href: '/users/sign_out' },
]
const props = defineProps({
    log_url: String
});
const logData = ref("");
const loading = ref(true);
// const themeName = "tomorrow-night-eighties-ansi-theme"
const themeName = "standard-console-ansi-theme"
fetch(props.log_url).then(response => response.text()).then(data => {

    var out = data.split(/\r?\n/).map(function (line) {
        var val  =   ansi_up.ansi_to_html(line, {use_classes: true});
        console.log(val);
        return val;
    }).map(function (line) {
        return "<div class='font-extralight	     text-xs font-mono leading-normal'>" +  line +  "</div>";
    }).join(' ');


   // var outData = data;
    logData.value = out;
    loading.value = false;
    console.log(logData);
}).catch(error => {
    logData.value = "Error fetching logs"
    console.log(error);
});


</script>
