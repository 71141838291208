<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-zinc-50">
    <div class="relative bg-indigo-600">
      <!-- Overlapping background -->
      <div
        aria-hidden="true"
        class="hidden absolute bg-zinc-50 w-full h-6 bottom-0 lg:block"
      />

      <div
        class="
          relative
          max-w-2xl
          mx-auto
          pt-16
          px-4
          text-center
          sm:pt-32 sm:px-6
          lg:max-w-7xl lg:px-8
        "
      >
        <h1
          class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl"
        >
          <span class="block lg:inline">Simple Pricing</span>
        </h1>
        <p class="mt-4 text-xl text-indigo-100">
          Starting from $0/month, all plans have access to game-changing
          concurrency.
        </p>
      </div>

      <h2 class="sr-only">Plans</h2>


      <!-- Cards -->
      <div
        class="
          relative
          mt-8
          max-w-2xl
          mx-auto
          px-4
          pb-8
          sm:mt-12 sm:px-6
          lg:max-w-7xl lg:px-8 lg:pb-0
        "
      >
        <!-- Decorative background -->
        <div
          aria-hidden="true"
          class="
            hidden
            absolute
            top-4
            bottom-6
            left-8
            right-8
            inset-0
            bg-indigo-700
            rounded-tl-lg rounded-tr-lg
            lg:block
          "
        />

        <div class="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
          <div
            v-for="plan in plans"
            :key="plan.title"
            :class="[
              plan.featured
                ? 'bg-white ring-2 ring-indigo-700 shadow-md'
                : 'bg-indigo-700 lg:bg-transparent',
              'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12',
            ]"
          >
            <div>
              <h3
                :class="[
                  plan.featured ? 'text-indigo-600' : 'text-white',
                  'text-sm font-semibold uppercase tracking-wide',
                ]"
              >
                {{ plan.title }}
              </h3>
              <div :class="[
                plan.featured ? 'text-indigo-600' : 'text-white',
              'mt-2 text-sm flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start',                  
               ]">
                Starting at
              </div>
              <div
                class="
                  flex flex-col
                  items-start
                  sm:flex-row sm:items-center sm:justify-between
                  lg:flex-col lg:items-start
                "
              >
                <div class="mt-3 flex items-center">
                  <p
                    :class="[
                      plan.featured ? 'text-indigo-600' : 'text-white',
                      'text-4xl font-extrabold tracking-tight',
                    ]"
                  >
                    ${{ plan.priceMonthly }}
                  </p>
                  <div class="ml-4">
                    <p
                      :class="[
                        plan.featured ? 'text-zinc-700' : 'text-white',
                        'text-sm',
                      ]"
                    >
                      USD / mo
                    </p>
                    <p
                      :class="[
                        plan.featured ? 'text-zinc-500' : 'text-indigo-200',
                        'text-sm',
                      ]"
                    >
                    </p>
                  </div>
                </div>
                <a
                  v-if="plan.should_email"
                  href="mailto:info@brisktest.com"
                  :class="[
                    plan.featured
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-white text-indigo-600 hover:bg-indigo-50',
                    'mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full',
                  ]"
                >
                  <span>Contact Us </span>
                </a>
                <form :action="plan.url" method="POST" class="w-full" v-else>
                  <button
                    type="submit"
                    :class="[
                      plan.featured
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-white text-indigo-600 hover:bg-indigo-50',
                      'mt-6  inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full',
                    ]"
                  >
                    <span v-if="plan.priceMonthly == 0"> Get Started </span>
                    <span v-else>Select {{ plan.title }} </span>
                  </button>
                </form>
              </div>
            </div>
            <h4 class="sr-only">Features</h4>
            <ul
              role="list"
              :class="[
                plan.featured
                  ? 'border-zinc-200 divide-zinc-200'
                  : 'border-indigo-500 divide-indigo-500 divide-opacity-75',
                'mt-7 border-t divide-y lg:border-t-0',
              ]"
            >
              <li
                v-for="mainFeature in plan.mainFeatures"
                :key="mainFeature.id"
                class="py-3 flex items-center"
              >
                <CheckIcon
                  :class="[
                    plan.featured ? 'text-indigo-500' : 'text-indigo-200',
                    'w-5 h-5 flex-shrink-0',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    plan.featured ? 'text-zinc-600' : 'text-white',
                    'ml-3 text-sm font-medium',
                  ]"
                  >{{ mainFeature.value }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Feature comparison (up to lg) -->

    <!-- Feature comparison (lg+) -->
  </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/solid";
import { inject } from "vue";
const props = defineProps({
  free_plan_url: String,
  developer_plan_url: String,
});
const plans = [
  {
    title: "Developer",
    featured: false,
    description: "",
    priceMonthly: 0,
    priceYearly: 0,
    url: props.free_plan_url,
    mainFeatures: [      
      { id: 1, value: "10,000 build minutes" },
      { id: 2, value: "20X concurrency" },
      { id: 3, value: "Great for faster tests" },
    ],
  },
  {
    title: "Team",
    featured: true,
    description: "",
    priceMonthly: 15,
    priceYearly: 150,
    url: props.developer_plan_url,
    mainFeatures: [
      { id: 1, value: "Everything on Developer" },
      {id: 2, value: "10,000 additional build minutes pay as you go for more"},
      { id: 3, value: "60X concurrency" },
      { id: 4, value: "14 Day Free Trial" },
      { id: 5, value: "Onboarding support" },
      { id: 6, value: "Priority email support" },
    ],
  },
  {
    title: "Enterprise",
    featured: false,
    description: "",
    priceMonthly: 2000,
    priceYearly: 20000,
    url: null,
    should_email: true,

    mainFeatures: [
      { id: 1, value: "Everything on Team" },
      { id: 2, value: "SSO, on-prem & more" },
      { id: 3, value: "14 Day Free Trial" },
      { id: 4, value: "Onboarding support" },
      //Priority email support
    ],
  },
];
const features = [
  {
    title: "Tax Savings",
    tiers: [
      { title: "starter", value: true },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Easy to use accounting",
    tiers: [
      { title: "starter", value: true },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Multi-accounts",
    tiers: [
      { title: "starter", value: "3 accounts" },
      { title: "popular", featured: true, value: "Unlimited accounts" },
      { title: "intermediate", value: "7 accounts" },
    ],
  },
  {
    title: "Invoicing",
    tiers: [
      { title: "starter", value: "3 invoices" },
      { title: "popular", featured: true, value: "Unlimited invoices" },
      { title: "intermediate", value: "10 invoices" },
    ],
  },
  {
    title: "Exclusive offers",
    tiers: [
      { title: "starter", value: false },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "6 months free advisor",
    tiers: [
      { title: "starter", value: false },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Mobile and web access",
    tiers: [
      { title: "starter", value: false },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: false },
    ],
  },
];
const perks = [
  {
    title: "24/7 customer support",
    tiers: [
      { title: "starter", value: true },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Instant notifications",
    tiers: [
      { title: "starter", value: true },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Budgeting tools",
    tiers: [
      { title: "starter", value: true },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Digital receipts",
    tiers: [
      { title: "starter", value: true },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Pots to separate money",
    tiers: [
      { title: "starter", value: false },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: true },
    ],
  },
  {
    title: "Free bank transfers",
    tiers: [
      { title: "starter", value: false },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: false },
    ],
  },
  {
    title: "Business debit card",
    tiers: [
      { title: "starter", value: false },
      { title: "popular", featured: true, value: true },
      { title: "intermediate", value: false },
    ],
  },
];
</script>