 import Chart from 'chart.js/auto';

 const chart =  () => {
  Array.from(document.getElementsByClassName("line-chart")).forEach((chart) => {
const ctx = chart.getContext('2d');
const labels = JSON.parse(chart.getAttribute('labels'))
const data = JSON.parse(chart.getAttribute('data'))
const title = chart.getAttribute('title')
console.log("data is ", data)
console.log("labels is ", labels)
const myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels:labels,
        datasets: [{
            label: title,
            data: data,
            maxBarThickness: 8,
            backgroundColor: [
                'rgba(120, 120, 200,.8)',
            ],
            borderWidth: 1
        }]
    },
    options: {
      scales: {
        yAxis: {
          // The axis for this scale is determined from the first letter of the id as `'x'`
          // It is recommended to specify `position` and / or `axis` explicitly.
          title: {text: "Seconds"}

      }
    }
}
  }
  );
  });
}

 export default chart
